$.mobile.autoInitializePage = false;


$(window).load(function(){
    $("[data-preloader]").fadeOut('fast',function(){$(this).remove();});
});

$(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
        $('.scroll-to-top').fadeIn();
    } else {
        $('.scroll-to-top').fadeOut();
    }
});

$('.scroll-to-top').on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop : 0}, 800);
});
$(document).ready(function () {
    var nHelper = $(".helper"),
       nExpand = $("[data-expand]"),
       mainSlider = $('[data-slider="mainSlider"]'),
       winnersSlider = $('[data-slider="winners"]'),
        featuredSlider = $('[data-slider="featured"]'),
       WIN = $(window),
       DOC = $(document),
       scrollTo = null,
        inputPass = document.getElementById('UserLogin_password'),
        inputChk  = document.getElementById('showpass'),
        label = document.getElementById('showhide'),
       isMacLike = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false,
       isIOS = navigator.platform.match(/(iPhone|iPod|iPad)/i) ? true : false,
        clRotated = "rotated";

    $('#showpass').on('click', function () {
        if(inputChk.checked) {

            inputPass.setAttribute('type', 'text');
        } else {
            inputPass.setAttribute('type', 'password');
        }
        var text = $('#showhide').data('pass-text');
        var alt_text = $('#showhide').text();

        label.textContent = text;
        $(label).data('pass-text',alt_text);
    });



   mainSlider.slick({
      dots: true,
      arrows: false,
      autoplay: true,
       autoplaySpeed: 4000,
       adaptiveHeight: false
   });

   winnersSlider.slick({
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            }
        ]
    });

    featuredSlider.slick({
        dots: false,
        arrows: true,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    });

    $(function(){
        var countLi = $('.winners__slide').length;
        if(countLi < 4) {
            $(".winners").addClass("clear");
        }
    });


   if(mainSlider.length > 0){
      $("main").addClass("main");
   }



   $(".header__langs__toggler").click(function () {
      $(this).toggleClass(clRotated).next(".header__langs__list").fadeToggle(200);
   });

   $(document).click(function (e) {
      if($(e.target).closest(".header__langs").length === 0){
         $(".header__langs__list").fadeOut(200);
         $(".header__langs__toggler").removeClass(clRotated);
      }
   });

    $(".header__user__menu__toggler").click(function () {
        $(this).toggleClass(clRotated).next(".header__user__menu__list").fadeToggle(200);
    });

    $("[data-button1]").click(function () {
        $("[data-gamegroup-menu]").fadeToggle(50);
        $(this).toggleClass("opened");
        if($("[data-button2]").hasClass('opened')){
            $("[data-providers-menu]").fadeOut(50);
            $("[data-button2]").removeClass('opened');
        }
    });
    $("[data-button2]").click(function () {
        $("[data-providers-menu]").fadeToggle(50);
        $(this).toggleClass("opened");
        if($("[data-button1]").hasClass('opened')){
            $("[data-gamegroup-menu]").fadeOut(50);
            $("[data-button1]").removeClass('opened');
        }
    });



    $(document).click(function (e) {
        if($(e.target).closest(".header__user__menu").length === 0 && $(e.target).closest().length === 0){
            $(".header__user__menu__list").fadeOut(200);
            $(".header__user__menu__toggler").removeClass(clRotated);
        }
    });

   function detailsToggle(toggler, content) {
      $(toggler).click(function (e) {
         e.preventDefault();

         $(this).toggleClass(clRotated).next(content).slideToggle(100);
      });
   }

   function checkMobile(element, wrapper, holder, type) {
      if (WIN.width() < 768){
         return type ? element.appendTo(wrapper) : element.prependTo(wrapper);
      } else {
         return type ? element.appendTo(holder) : element.prependTo(holder);
      }
   }

   function preventScroll() {
      $("body").bind('mousewheel DOMMouseScroll touchmove', function (event) {

         if (event.type == 'mousewheel') {
            scrollTo = (event.originalEvent.wheelDelta * -1);
         }

         else if (event.type == 'DOMMouseScroll') {
            scrollTo = 40 * event.originalEvent.detail;
         }

         if (scrollTo) {
            event.preventDefault();
            $(this).scrollTop(scrollTo + $(this).scrollTop());
         }

         return false;
      });
   }

   if (isMacLike || isIOS){
      $("html").addClass("mac-os");
   }



   detailsToggle($("[data-bonus-toggle]"), $("[data-bonus-content]"));

    var readURL = function(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                /*$('.profile-pic').attr('src', e.target.result);*/
            };

            reader.readAsDataURL(input.files[0]);
        }
    };

    $(".file-upload").on('change', function(){
        readURL(this);
    });

    $(".upload-button").on('click', function() {
        $(".file-upload").click();
    });

    $('select').selectmenu();

    $(".currency").selectmenu({
        classes: {
            "ui-selectmenu-menu":"ui-menu-currency"
        }
    });
    $(window).resize(function() {
        $('[role="combobox"][aria-expanded="true"]').each(function () { // anyone that is open
            $(this).prev().selectmenu("close").selectmenu("open");
        });
    });
    function focusInput () {
        $("input").on("focusin", function () {
            $(this).parent().addClass("focused");
        }).on("focusout", function () {
            $(this).parent().removeClass("focused");
        });

        $("textarea").on("focusin", function () {
            $(this).parent().addClass("focused");
        }).on("focusout", function () {
            $(this).parent().removeClass("focused");
        });
    }

    focusInput();

    $(window).on("opened.ceModal", function () {
        focusInput();
    });

    $("[data-expand]").click(function () {
        $("[data-mobile]").fadeToggle(50);
        $(this).toggleClass("expanded");
    });

    $("[data-favorite]").click(function (e) {
        e.preventDefault();

        $("[data-featured-slider]").toggleClass("displayed");
        $(this).toggleClass("pressed");
        $("[data-mobile]").fadeOut(200); //!
        $(this).removeClass("expanded"); //!
        $('.expanded').removeClass('expanded');
        if($("[data-button2]").hasClass('opened')){
            $("[data-providers-menu]").fadeOut(50);
            $("[data-button2]").removeClass('opened');
        }
        if($("[data-button1]").hasClass('opened')){
            $("[data-gamegroup-menu]").fadeOut(50);
            $("[data-button1]").removeClass('opened');
        }

    });

    $("[data-featured-slider-close]").click(function (e) {
        e.preventDefault();

        $("[data-featured-slider]").removeClass("displayed");
        $("[data-favorite]").removeClass("pressed");
    });

    // move blocks

    function moveDom(element) {
        $(element).responsiveDom({
            appendTo: element.replace("]", "-holder]"),
            mediaQuery: "(max-width: 767px)"
        });
    }

    moveDom("[data-favorite]");
    moveDom("[data-lang]");
    moveDom("[data-menu]");
    moveDom("[data-providers-menu]");
    moveDom("[data-chat]");

    function moveDomTablet(element) {
        $(element).responsiveDom({
            appendTo: element.replace("]", "-holder]"),
            mediaQuery: "(max-width: 768px)"
        });
    }

});






$( function() {
    $( ".transaction-history__tabs" ).tabs();
} );
// docs upload

$(document).ready(function() {
    $("textarea").niceScroll({cursorcolor:"transparent", cursorborder:"none"});
    $(".ui-menu").niceScroll({cursorcolor:"transparent", cursorborder:"none"});
    $(".country-list").niceScroll({cursorcolor:"transparent", cursorborder:"none"});

});


